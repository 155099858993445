import React from "react";
import { MainButton, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import { PassifyApi, doAuthenticated, executeRecaptcha } from "../../shared";
import "./forgotpasswordview.scss";

const ForgotPasswordView = () => {
    const navigate = useNavigate()

    const [email, setEmail] = React.useState<string>("");

    React.useEffect(() => {
		doAuthenticated(async auth => {
			PassifyApi.getCustomerData(auth.accessToken).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						if(responseJSON["succeeded"] && responseJSON["value"]["email"] != null && responseJSON["value"]["email"] != "") {
							navigate("/ticket-selection");
						}
					});
				}
			});
		}, () => {});
    }, []);

    const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value);
        e.preventDefault();
    };

    const resetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        executeRecaptcha((token: any) => {
        PassifyApi.forgotPassword(token, email).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"]) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "resetPassword",
                                email: email.trim(),
                            }));
                            navigate("/verification");
                        }
                    });
                }
            });
        });
        e.preventDefault();
    }

    return <div id="forgot-password">
        <div className="forgot-password-prompt">Esqueceu sua senha? Não tem problema, nos informa seu e-mail que vamos mandar um código para que você possa resetá-la:</div>
        <TextInput id="forgot-password-email" name="email" value={email} icon={{imageIconUrl: "/images/email.svg", width: 16.67, height: 15}} placeholder="Seu e-mail" onChange={changeEmailHandle} />
        <MainButton content="Resetar senha" onClick={resetPassword} />
    </div>;
}

export { ForgotPasswordView };