import React from "react";
import { PassifyApi, Reservation, Segment, doAuthenticated, executeRecaptcha, refreshAuth, setAuth } from "../../shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { MainButton } from "../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ticketselectionview.scss";

interface SelectedTickets {
    [id: string]: number,
}

interface TicketSelectionProps {
    eventSessionId: string,
}

const TicketSelectionView = (props: TicketSelectionProps) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const feeRate = 0.1;
    const maxAmountPerTicket = 2;
    const maxAmount = 2;

    const [availableTickets, setAvailableTickets] = React.useState<Array<Segment>>([]);
    const [selectedTickets, setSelectedTickets] = React.useState<SelectedTickets>({});
    const [discount, setDiscount] = React.useState<number>(0);
    const [reservations, setReservations] = React.useState<Array<Reservation>>([]);

    let actualReservations: Array<Reservation> = [];

    const loadAvailableTickets = async () => {
        const response = await PassifyApi.getAvailableTickets(props.eventSessionId, searchParams.get("code"));
        if(response.status == 200) {
            const responseJSON = await response.json();
            if(responseJSON["succeeded"]) {
                if(searchParams.get("code") != null) {
                    if(responseJSON["value"].length == 0 || (responseJSON["value"] as Array<Segment>).reduce((prev, cur) => prev + cur.ticketBatches.length, 0) == 0) {
                        setSearchParams("");
                        navigate("/ticket-selection");
                    }
                }
                setAvailableTickets(responseJSON["value"]);
            }
        }
    }

    React.useEffect(() => {
        loadAvailableTickets();
    }, []);

    React.useEffect(() => {
        loadAvailableTickets();
    }, [searchParams]);

    const addTicket = (batchId: string) => {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            if(ticketsSelected < maxAmount && (selectedTickets[batchId] ?? 0) < maxAmountPerTicket) {
                setSelectedTickets(oldSelected => ({
                    ...oldSelected,
                    [batchId]: (oldSelected[batchId] ?? 0) + 1
                }));
            }
            e.preventDefault();
        }
    }

    const removeTicket = (batchId: string) => {
        return (e: React.MouseEvent<HTMLButtonElement>) => {
            setSelectedTickets(oldSelected => ({
                ...oldSelected,
                [batchId]: Math.max((oldSelected[batchId] ?? 0) - 1, 0)
            }));
            e.preventDefault();
        }
    }

    const ticketsSelected = availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.reduce((prev, cur) => prev + (selectedTickets[cur.id] ?? 0), 0), 0);
    const subtotal = availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.reduce((prev, cur) => prev + (selectedTickets[cur.id] ?? 0) * cur.price, 0), 0);
    const feeAmount = feeRate * subtotal;

    const dropSession = async () => {
        navigate("/signin");
    }

    const loadReservations = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getReservations(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    actualReservations = responseJSON["value"];
                    setReservations(actualReservations);
                }
            } else if(response.status == 401) {
                refreshAuth(loadReservations, dropSession);
            }
        }, dropSession);
    }

    const updateReservation = async (reservationId: string, isActive: boolean, quantity: number) => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.updateReservation(auth.accessToken, reservationId, isActive, quantity);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    setReservations(oldReservations => oldReservations.map(reservation => {
                        if(reservation.id == reservationId) {
                            return {...reservation, isActive: isActive, quantity: quantity};
                        }
                        return reservation;
                    }));
                }
            } else if(response.status == 401) {
                await refreshAuth(async () => { await updateReservation(reservationId, isActive, quantity); }, dropSession);
            }
        }, dropSession);
    }

    const addReservation = async (batchId: string, quantity: number) => {
        await doAuthenticated(async auth => {
            executeRecaptcha(async (token: any) => {
                const response = await PassifyApi.reserveTicket(token, auth.accessToken, batchId, quantity, searchParams.get("code") ?? undefined);
                if(response.status == 200) {
                    const responseJSON = await response.json();
                    if(responseJSON["succeeded"]) {
                        setReservations(oldReservations => [...oldReservations, responseJSON["value"] as Reservation]);
                    }
                } else if(response.status == 401) {
                    await refreshAuth(async () => { await addReservation(batchId, quantity); }, dropSession);
                } else {
                    const responseJSON = await response.json();
                    if(!responseJSON["succeeded"] && responseJSON["errors"].length > 0) {
                        switch(responseJSON["errors"][0]["code"]) {
                            case 404:
                            case 1001:
                                window.alert("Quantidade de ingressos indisponível.");
                                break;
                        }
                    }
                }
            });
        }, dropSession);
    }

    const doReservations = async () => {
        const batches = availableTickets.map(segment => segment.ticketBatches.map(batch => batch.id)).flat();
        for(let i = 0; i < batches.length; i++) {
            const batchId = batches[i];
            const updatedReservation = actualReservations.find(reservation => reservation.batch.id == batchId);
            if(updatedReservation != undefined) {
                await updateReservation(updatedReservation.id, true, selectedTickets[batchId]);
            } else if(selectedTickets[batchId] > 0) {
                await addReservation(batchId, selectedTickets[batchId]);
            }
        }
    }

    const createNewBuyer = async () => {
        executeRecaptcha(async (token: any) => {
            const response = await PassifyApi.createNewBuyer(token);
            if(response.status == 200) {
                const responseJSON = await response.json();
                setAuth(responseJSON["access_token"], responseJSON["refresh_token"], false);
            }
        });
    }

    const checkAuth = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 401) {
                await createNewBuyer();
            }
        }, async () => { await createNewBuyer(); });
    }

    const addToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
        checkAuth().then(() => {
            loadReservations().then(() => {
                doReservations().then(() => {
                    navigate("/review-summary");
                });
            });
        });
        e.preventDefault();
    }

    return <div id="ticket-selection">
        {searchParams.get("code") != null && <div className="code-active-prompt">
            Código <b>{searchParams.get("code")}</b> está ativo!
        </div>}
        {availableTickets.reduce((prev, cur) => prev + cur.ticketBatches.length, 0) > 0 ? <>
            {availableTickets.map(segment => segment.ticketBatches.length > 0 ? <div className="segment">
                <div className="segment-name">{segment.name}</div>
                <div className="segment-batches">
                    {segment["ticketBatches"].map(batch => <div className="batch">
                        <div className="batch-info">
                            <div className="batch-name">{batch.name}</div>
                            <div className="batch-price">R$ {batch.price.toFixed(2)}</div>
                        </div>
                        <div className="batch-selection-quantity">
                            <div className="batch-selector">
                                <div><button onClick={removeTicket(batch.id)} disabled={(selectedTickets[batch.id] ?? 0) == 0}><FontAwesomeIcon icon={faMinus} /></button></div>
                                <div className="batch-quantity-display">{selectedTickets[batch.id] ?? 0}</div>
                                <div><button onClick={addTicket(batch.id)}><FontAwesomeIcon icon={faPlus} /></button></div>
                            </div>
                            <div className="batch-subtotal">
                                Total: R$ {((selectedTickets[batch.id] ?? 0) * batch.price).toFixed(2)}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div> : <></>)}
            <div className="summary">
                {false && <div className="summary-item discount">
                    Aplicar código de desconto:
                    <div className="discount-form">
                        <input />
                        <button><FontAwesomeIcon icon={faPlay} /></button>
                    </div>
                </div>}
                <div className="summary-item">
                    Subtotal: R$ {subtotal.toFixed(2)}
                </div>
                <div className="summary-item">
                    Taxa de serviço: R$ {feeAmount.toFixed(2)}
                </div>
                {discount > 0 && <div className="summary-item">
                    Desconto: - R$ {discount.toFixed(2)}
                </div>}
                <div className="summary-item total">
                    Total: R$ {(subtotal + feeAmount).toFixed(2)}
                </div>
            </div>
            <div className="checkout-button-container">
                <MainButton enabled={subtotal > 0} content="Ir para pagamento" onClick={addToCart} />
            </div>
        </> : <div className="code-active-prompt">
            {(new Date().getTime() < new Date(2023, 4, 17, 12).getTime()) ? "Abertura das vendas a partir de 12h do dia 17/05" : "Não há ingressos a venda!"}
        </div>}
        <div className="passify-logo">
            <img src="/images/passify-dark.svg" width={64} />
        </div>
    </div>;
}

export { TicketSelectionView };