import React from "react";
import md5 from "md5";
import { MainButton, SecondaryButton, TextInput } from "../../components";
import { CpfFormatter, EmailFormatter, PassifyApi, PhoneFormatter, doAuthenticated, refreshAuth, resetAuth } from "../../shared";
import { useNavigate } from "react-router-dom";
import "./profileview.scss";
import { DateFormatter } from "../../shared/inputformatters/dateformatter";

interface ProfileProps {
}

const ProfileView = (props: ProfileProps) => {
    const navigate = useNavigate();

    const [profilePic, setProfilePic] = React.useState<string>("");

    const [email, setEmail] = React.useState<string>("");
    const [emailCursor, setEmailCursor] = React.useState<number>(0);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const emailFormatter = new EmailFormatter();

    const [name, setName] = React.useState<string>("");
    const nameRef = React.useRef<HTMLInputElement>(null);

    const [cpf, setCpf] = React.useState<string>("");
    const [cpfCursor, setCpfCursor] = React.useState<number>(0);
    const cpfRef = React.useRef<HTMLInputElement>(null);
    const cpfFormatter = new CpfFormatter();

    const [dob, setDob] = React.useState<string>("");
    const [dobCursor, setDobCursor] = React.useState<number>(0);
    const dobRef = React.useRef<HTMLInputElement>(null);
    const dateFormatter = new DateFormatter();

    const [phone, setPhone] = React.useState<string>("");
    const [phoneCursor, setPhoneCursor] = React.useState<number>(0);
    const phoneRef = React.useRef<HTMLInputElement>(null);
    const phoneFormatter = new PhoneFormatter();

    const [currentPhone, setCurrentPhone] = React.useState<string>("");

    const dropSession = async () => {
        navigate("/signin");
    }

    const getCustomerData = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    if(responseJSON["value"]["email"] == null || responseJSON["value"]["email"] == "") {
                        dropSession();
                    }

                    const dobParts = (responseJSON["value"]["dateOfBirth"] as (string | null) ?? "").split("-");

                    setProfilePic(`https://gravatar.com/avatar/${md5(responseJSON["value"]["email"])}?d=retro`);
                    setEmail(responseJSON["value"]["email"]);
                    setName(responseJSON["value"]["name"] || "");
                    setCpf(cpfFormatter.mask(responseJSON["value"]["taxId"] || "") || "");
                    setDob(dobParts.length >= 3 ? `${dobParts[2]}/${dobParts[1]}/${dobParts[0]}` : "");
                    setPhone(phoneFormatter.mask(responseJSON["value"]["phone"] || "") || "");
                    setCurrentPhone(responseJSON["value"]["phone"]);
                }
            } else if(response.status == 401) {
                await refreshAuth(getCustomerData, dropSession);
            }
        }, dropSession);
    }

    React.useEffect(() => {
        getCustomerData();
    }, []);

    const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setEmail(updatedValue.text);
        setEmailCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = emailRef.current;
        if(input != null) {
            input.setSelectionRange(emailCursor, emailCursor);
        }
    }, [emailRef, emailCursor, email]);

    const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
        e.preventDefault();
    }

    const changeCpfHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = cpfFormatter.updateValue({text: cpf, cursor: cpfCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setCpf(updatedValue.text);
        setCpfCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = cpfRef.current;
        if(input != null) {
            input.setSelectionRange(cpfCursor, cpfCursor);
        }
    }, [cpfRef, cpfCursor, cpf]);

    const changeDobHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = dateFormatter.updateValue({text: dob, cursor: dobCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setDob(updatedValue.text);
        setDobCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = dobRef.current;
        if(input != null) {
            input.setSelectionRange(dobCursor, dobCursor);
        }
    }, [dobRef, dobCursor, dob]);

    const changePhoneHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = phoneFormatter.updateValue({text: phone, cursor: phoneCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setPhone(updatedValue.text);
        setPhoneCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = phoneRef.current;
        if(input != null) {
            input.setSelectionRange(phoneCursor, phoneCursor);
        }
    }, [phoneRef, phoneCursor, phone]);

    const saveCustomerData = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.saveCustomerData(auth.accessToken, name, cpfFormatter.unmask(cpf), dateFormatter.unmask(dob));
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    window.alert("Perfil atualizado com sucesso!");
                }
            } else if(response.status == 401) {
                await refreshAuth(saveCustomerData, dropSession);
            }
        }, dropSession);
    }

    const requestPhoneChange = async () => {
        await doAuthenticated(async auth => {
            const unmaskedPhone = phoneFormatter.unmask(phone);
            const updatePhone = unmaskedPhone[0] != "+" ? `+55${unmaskedPhone}` : unmaskedPhone;
            if(updatePhone != currentPhone) {
                const response = await PassifyApi.requestPhoneChange(auth.accessToken, updatePhone);
                if(response.status == 200) {
                    // Change form to a confirmation one
                } else if(response.status == 401) {
                    await refreshAuth(requestPhoneChange, dropSession);
                }
            }
        }, dropSession);
    }

    const signout = (e: React.MouseEvent<HTMLButtonElement>) => {
        resetAuth();
        dropSession();
        e.preventDefault();
    }

    const save = (e: React.MouseEvent<HTMLButtonElement>) => {
        saveCustomerData().then(() => {
            requestPhoneChange();
        });
        e.preventDefault();
    }

    return <div id="profile">
        <div id="profile-picture-container">
            <img src={profilePic} />
        </div>
        <div className="profile-form-field">
            <div className="label">E-mail</div>
            <TextInput ref={emailRef} id="profile-email" name="email" enabled={false} value={email} icon={{imageIconUrl: "/images/email.svg", width: 16.67, height: 15}} placeholder="E-mail" onChange={changeEmailHandle} />
        </div>
        <div className="profile-form-field">
            <div className="label">Nome</div>
            <TextInput ref={nameRef} id="profile-name" name="name" value={name} icon={{imageIconUrl: "/images/user.svg", width: 16.67, height: 19}} placeholder="Nome" onChange={changeNameHandle} />
        </div>
        <div className="profile-form-field">
            <div className="label">CPF</div>
            <TextInput ref={cpfRef} id="profile-cpf" name="cpf" value={cpf} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000.000.000-00" onChange={changeCpfHandle} />
        </div>
        <div className="profile-form-field">
            <div className="label">Data de aniversário</div>
            <TextInput ref={dobRef} id="profile-dob" name="dob" value={dob} icon={{imageIconUrl: "/images/calendar.svg", width: 16.67, height: 19}} placeholder="dd/mm/aaaa" onChange={changeDobHandle} />
        </div>
        <div className="profile-form-field">
            <div className="label">Telefone</div>
            <TextInput ref={phoneRef} id="profile-phone" name="phone" enabled={false} value={phone} icon={{imageIconUrl: "/images/phone.svg", width: 16.67, height: 16.67}} placeholder="(11) 99999-9999" onChange={changePhoneHandle} />
        </div>
        <MainButton content="Salvar perfil" onClick={save} />
        <SecondaryButton content="Sair da conta" onClick={signout} />
        <div className="terms"><a href="https://passify.co/politica-de-privacidade.pdf" target="_blank">Política de privacidade</a> | <a href="https://passify.co/termos-de-servico.pdf" target="_blank">Termos de serviço</a></div>
    </div>;
}

export { ProfileView };