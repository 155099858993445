import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses, faHeadset, faHome, faQrcode, faSignIn, faUser, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PassifyApi, doAuthenticated } from "./shared";
import "./app.scss";

interface AppProps {
}

const App = (props: AppProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [auth, setAuth] = React.useState<string | null>(null);
    const [customerEmail, setCustomerEmail] = React.useState<string>();

    React.useEffect(() => {
        loadAuth();
	}, []);

    const forceRegisterPhone = () => {
        doAuthenticated(async auth => {
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"] && responseJSON["value"]["email"] != null && responseJSON["value"]["email"] != "") {
                    setCustomerEmail(responseJSON["value"]["email"]);
                    if(responseJSON["value"]["phone"] == null || responseJSON["value"]["phone"] == "") {
                        const localWhatsappBypass = localStorage.getItem(`whatsappBypass-${responseJSON["value"]["email"]}`);
                        if(localWhatsappBypass == null) {
                            navigate("/register-phone");
                        }
                    }
                }
            }
        }, () => {});
    }

    React.useEffect(() => {
        loadAuth();
        if(["/register-phone", "/verification", "/signout"].indexOf(location.pathname) ==  -1) {
            forceRegisterPhone();
        }
	}, [location]);

    React.useEffect(() => {
        forceRegisterPhone();
    }, [auth]);

    const loadAuth = () => {
        const localAuth = localStorage.getItem("auth");
        const sessionAuth = sessionStorage.getItem("auth");
        if(localAuth != null && localAuth != sessionAuth) {
            sessionStorage.setItem("auth", localAuth);
            setAuth(localAuth);
        } else {
		    setAuth(sessionAuth);
        }
    }

	const goToHome = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/");
        e.preventDefault();
    }

    const goToCyberpunk = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/cyberpunk");
        e.preventDefault();
    }

    const goToPass = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/pass");
        e.preventDefault();
    }

    const goToWallet = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/wallet");
        e.preventDefault();
    }

    const goToSignin = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/signin");
        e.preventDefault();
    }

    const goToProfile = (e: React.MouseEvent<HTMLLIElement>) => {
        navigate("/profile");
        e.preventDefault();
    }

    return (
        <div id="app">
            <header id="app-header">
                <div className="app-header-corner left"></div>
				<h1><img id="app-logo" src="/images/retro-logo.png" alt="Passify" /></h1>
                <div className="app-header-corner right"><a href="https://wa.me/5511956621753"><FontAwesomeIcon icon={faHeadset} /></a></div>
			</header>
            <div id="app-body">
                <Outlet />
            </div>
            <div id="app-navbar">
                <ul>
                    <li className={location.pathname == "" || location.pathname == "/" || ["/ticket-selection", "/review-summary", "/payment"].find(path => location.pathname.indexOf(path) == 0) != undefined ? "active" : ""} onClick={goToHome}><FontAwesomeIcon icon={faHome} /></li>
                    <li className={location.pathname == "/wallet" ? "active" : ""} onClick={goToWallet}><FontAwesomeIcon icon={faWallet} /></li>
                    <li className={location.pathname == "/pass" ? "active" : ""} onClick={goToPass}><FontAwesomeIcon icon={faQrcode} /></li>
                    <li className={location.pathname == "/cyberpunk" ? "active" : ""} onClick={goToCyberpunk}><FontAwesomeIcon icon={faGlasses} /></li>
                    {auth != null && customerEmail != null ?
                        <li className={["/profile", "/verification", "/register-phone"].indexOf(location.pathname) != -1 ? "active" : ""} onClick={goToProfile}><FontAwesomeIcon icon={faUser} /></li> :
                        <li className={["/signin", "/signup", "/forgot-password", "/verification"].indexOf(location.pathname) != -1 ? "active" : ""} onClick={goToSignin}><FontAwesomeIcon icon={faSignIn} /></li>
                    }
                </ul>
            </div>
        </div>
    );
}

export { App };
