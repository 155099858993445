import React from "react";
import { useNavigate } from "react-router-dom";
import { MainButton } from "../../components";
import { PassifyApi, doAuthenticated, resetAuth } from "../../shared";
import "./signoutview.scss"

interface SignoutProps {
}

const SignoutView = (props: SignoutProps) => {
    const navigate = useNavigate();

    const dropSession = () => {
        navigate("/signin");
    }

    React.useEffect(() => {
        doAuthenticated(async auth => {
            PassifyApi.getCustomerData(auth.accessToken).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"] && (responseJSON["value"]["email"] == null || responseJSON["value"]["email"] == "")) {
                            dropSession();
                        }
                    });
                } else if(response.status == 401) {
                    dropSession();
                }
            });
        }, dropSession);
    }, []);

    const signout = (e: React.MouseEvent<HTMLButtonElement>) => {
        resetAuth();
        dropSession();
        e.preventDefault();
    }

    return <div id="signout">
        <div id="signout-text">Quer sair mesmo? 😮</div>
        <MainButton content="Sair" onClick={signout} />
    </div>;
}

export { SignoutView }