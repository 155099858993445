import { Slider } from "../../components";
import "./cyberpunkview.scss";

const CyberpunkView = () => {
    return <div id="cyberpunk">
        <div id="cyberpunk-title">O Cyberpunk</div>
        <Slider />
    </div>;
}

export { CyberpunkView };