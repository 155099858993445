class PassifyApi {
    private static _host = "https://api.passify.co";

    private static _get = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "GET"
        });
    }

    private static _post = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "POST"
        });
    }

    private static _put = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "PUT"
        });
    }

    private static _delete = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "DELETE"
        });
    }

    // OpenId

    static connect = async (recaptchaToken: string, username: string, password: string) => {
        return await this._post("/connect/token", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `client_id=web&client_secret=secret&grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
        });
    }

    static refreshToken = async (refreshToken: string) => {
        return await this._post("/connect/token", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `client_id=web&client_secret=secret&grant_type=refresh_token&refresh_token=${encodeURIComponent(refreshToken)}`
        });
    }

    // Auth

    static createNewBuyer = async (recaptchaToken: string) => {
        return await this._post("/auth/new-buyer", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
            }
        });
    }

    static register = async (recaptchaToken: string, email: string, password?: string) => {
        return await this._post("/auth/register", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "password": password,
            })
        });
    }

    static resendConfirmation = async (email: string) => {
        return await this._post("/auth/resend-confirmation", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
            })
        });
    }

    static confirmEmail = async (email: string, code: string, password?: string) => {
        return await this._post("/auth/confirm", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "code": code,
                "password": password,
            })
        });
    }

    static facebookSignin = async (accessToken: string) => {
        return await this._post("/auth/facebook", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "accessToken": accessToken,
            })
        });
    }

    static googleSignin = async (code: string) => {
        return await this._post("/auth/google-oidc", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "code": code,
            })
        });
    }

    static forgotPassword = async (recaptchaToken: string, email: string) => {
        return await this._post("/auth/forgot-password", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
            })
        });
    }

    static resetPassword = async (email: string, code: string, password: string) => {
        return await this._post("/auth/reset-password", {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "code": code,
                "password": password,
            })
        });
    }

    // Checkout

    static getAvailableTickets = async (eventSessionId: string, code?: string | null) => {
        return await this._get(`/checkout/available/${eventSessionId}${code != undefined ? `?code=${code}` : ""}`);
    }

    static getReservations = async (accessToken: string) => {
        return await this._get("/checkout/reservation", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static reserveTicket = async (recaptchaToken: string, accessToken: string, batchId: string, quantity: number, code?: string) => {
        return await this._post("/checkout/reservation", {
            headers: {
                "X-Recaptcha-Token": recaptchaToken,
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "ticketBatchId": batchId,
                "quantity": quantity,
                "code": code,
            })
        });
    }

    static updateReservation = async (accessToken: string, reservationId: string, isActive: boolean, quantity: number) => {
        return await this._put(`/checkout/reservation/${reservationId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "id": reservationId,
                "isActive": isActive,
                "quantity": quantity,
            })
        });
    }

    static getCheckoutOrders = async (accessToken: string) => {
        return await this._get("/checkout/order", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static getCheckoutOrder = async (accessToken: string, orderId: string) => {
        return await this._get(`/checkout/order/${orderId}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static createCheckoutOrder = async (accessToken: string, reservations: Array<string>, email?: string) => {
        return await this._post("/checkout/order", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "reservations": reservations,
                "email": email,
            })
        });
    }

    static choosePixPayment = async (accessToken: string, orderId: string, taxId: string, name?: string) => {
        return await this._put(`/checkout/order/${orderId}/payment`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "pix": {
                    "taxId": taxId,
                    "name": name,
                }
            })
        });
    }

    static chooseCardPayment = async (accessToken: string, orderId: string, taxId: string, cardPaymentToken: string, paymentMethodId: string) => {
        return await this._put(`/checkout/order/${orderId}/payment`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "card": {
                    "taxId": taxId,
                    "token": cardPaymentToken,
                    "installments": 1,
                    "paymentMethodId": paymentMethodId,
                }
            })
        });
    }

    // Customer

    static getCustomerData = async (accessToken: string) => {
        return await this._get("/customer/data", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static saveCustomerData = async (accessToken: string, name: string, cpf: string, dob: string) => {
        return await this._put("/customer/data", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "name": name,
                "taxId": cpf,
                "dateOfBirth": dob
            })
        });
    }

    static getCustomerWallet = async (accessToken: string) => {
        return await this._get("/customer/wallet", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static saveCustomerWallet = async (accessToken: string, address: string) => {
        return await this._put("/customer/wallet", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "address": address,
            })
        });
    }

    static getPasses = async (accessToken: string) => {
        return await this._get("/customer/pass", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        });
    }

    static requestPhoneChange = async (accessToken: string, phone: string) => {
        return await this._post("/customer/phone/request", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "phone": phone,
            })
        });
    }

    static changePhone = async (accessToken: string, phone: string, code: string) => {
        return await this._post("/customer/phone", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "phone": phone,
                "code": code,
            })
        });
    }

    static getPassSessionMessage = async (accessToken: string) => {
        return await this._get("/customer/message", {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        });
    }

    // Event

    static getEvents = async () => {
        return await this._get("/event", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    static getEventDetails = async (eventId: string) => {
        return await this._get(`/event/${eventId}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    static getEventsCategories = async () => {
        return await this._get("/event/category", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }
}

export { PassifyApi };