import React from "react";
import { useNavigate } from "react-router-dom";
import "./homeview.scss";

interface HomeProps {
}

const HomeView = (props: HomeProps) => {
    const navigate = useNavigate();

    const goToTicketSelection = () => {
        navigate("/ticket-selection");
    }

    return <div id="home">
        <div className="passify-logo">
            <a href="https://passify.co"><img src="/images/passify-dark.svg" /></a>
        </div>
        <video className="video-mobile" src="/videos/mobile.mp4" autoPlay={true} loop={true} playsInline={true} muted={true}></video>
        <video className="video-desktop" src="/videos/desktop.mp4" autoPlay={true} loop={true} playsInline={true} muted={true}></video>
        <div className="event-date">
            27/05 às 21h
            <small>(local da festa será divulgado no dia)</small>
        </div>
        <div className="cta-container">
            <button onClick={goToTicketSelection}>COMPRAR INGRESSO</button>
        </div>
    </div>;
}

export { HomeView };