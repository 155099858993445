import React from "react";
import { CheckboxInput, MainButton, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import { PassifyApi, doAuthenticated, executeRecaptcha, fbLogin, setAuth } from "../../shared";
import "./signinview.scss";
import { useGoogleLogin } from "@react-oauth/google";

interface SigninProps {
}

const SigninView = (props: SigninProps) => {
    const navigate = useNavigate();

  	const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [rememberMe, setRememberMe] = React.useState<boolean>(false);

    React.useEffect(() => {
		doAuthenticated(async auth => {
			PassifyApi.getCustomerData(auth.accessToken).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						if(responseJSON["succeeded"] && responseJSON["value"]["email"] != null && responseJSON["value"]["email"] != "") {
							navigate("/ticket-selection");
						}
					});
				}
			});
		}, () => {});
    }, []);

  	const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
    	setEmail(e.currentTarget.value);
    	e.preventDefault();
  	}

    const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
    	setPassword(e.currentTarget.value);
    	e.preventDefault();
  	}

	const clickRememberMeHandle = (e: React.FormEvent<HTMLInputElement>) => {
		setRememberMe(oldRememberMe => !oldRememberMe);
		e.preventDefault();
  	}

    const signin = (e: React.MouseEvent<HTMLButtonElement>) => {
		executeRecaptcha((token: any) => {
			PassifyApi.connect(token, email.trim(), password).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						setAuth(responseJSON["access_token"], responseJSON["refresh_token"], rememberMe);
						navigate("/ticket-selection");
					});
				} else {
					window.alert("Login inválido");
				}
			});
		});
        e.preventDefault();
    }

    const signinWithFacebook = (e: React.MouseEvent<HTMLButtonElement>) => {
		fbLogin((authResponse: any) => {
				PassifyApi.facebookSignin(authResponse.accessToken).then(response => {
					if(response.status == 200) {
						response.json().then(responseJSON => {
							setAuth(responseJSON["access_token"], responseJSON["refresh_token"], rememberMe);
							navigate("/ticket-selection");
						});
					} else {
						window.alert("Login inválido");
					}
				});
			}
		);
        e.preventDefault();
    }

	const googleLogin = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async codeResponse => {
			PassifyApi.googleSignin(codeResponse.code).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						setAuth(responseJSON["access_token"], responseJSON["refresh_token"], rememberMe);
						navigate("/ticket-selection");
					});
				} else {
					window.alert("Login inválido");
				}
			});
		},
		onError: console.log,
	});

	const signinWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
        googleLogin();
        e.preventDefault();
    }

    const goToForgotPassword = (e: React.MouseEvent<HTMLAnchorElement>) => {
        navigate("/forgot-password");
        e.preventDefault();
    }

	const goToSignup = (e: React.MouseEvent<HTMLAnchorElement>) => {
        navigate("/signup");
        e.preventDefault();
    }

	return (
		<div id="signin">
			<div id="signin-form">
				<TextInput id="signin-email" name="email" value={email} icon={{imageIconUrl: "/images/email.svg", width: 16.67, height: 15}} placeholder="E-mail" onChange={changeEmailHandle} />
				<TextInput id="signin-password" name="password" password={true} value={password} icon={{imageIconUrl: "/images/password.svg", width: 14, height: 16.67}} placeholder="Senha" onChange={changePasswordHandle} />
				<div></div>
				<CheckboxInput id="signin-remember-me" name="remember-me" checked={rememberMe} text="Manter-me logado" onClick={clickRememberMeHandle} />
				<div></div>
				<MainButton content="Entrar" onClick={signin} />
                <div id="signin-forgot-password">Esqueceu sua senha? <a onClick={goToForgotPassword}>A gente resolve!</a></div>
				<div id="signin-social">
					<div id="signin-social-separator">
						<hr />
						<div>ou continue com</div>
						<hr />
					</div>
					<div id="signin-social-buttons">
						<button onClick={signinWithFacebook}><img src="/images/facebook.svg" /></button>
						<button onClick={signinWithGoogle}><img src="/images/google.svg" /></button>
						{false && <button><img src="/images/apple.svg" /></button>}
					</div>
				</div>
                <div id="signin-signup-text">Não tem uma conta Passify? <a onClick={goToSignup}>Cadastre-se</a></div>
			</div>
		</div>
	);
};

export { SigninView };