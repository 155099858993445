import React from "react";
import { MainButton, TextInput } from "../../components";
import { useNavigate } from "react-router-dom";
import { PassifyApi, PhoneFormatter, doAuthenticated, refreshAuth } from "../../shared";
import "./registerphoneview.scss";

const RegisterPhoneView = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = React.useState<boolean>(true);
    const [email, setEmail] = React.useState<string>("");
    const [phone, setPhone] = React.useState<string>("");
    const [phoneCursor, setPhoneCursor] = React.useState<number>(0);
    const phoneRef = React.useRef<HTMLInputElement>(null);
    const phoneFormatter = new PhoneFormatter();

    const dropSession = async () => {
        navigate("/signin");
    };

    React.useEffect(() => {
        doAuthenticated(async auth => {
            PassifyApi.getCustomerData(auth.accessToken).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						if(responseJSON["succeeded"] && responseJSON["value"]["email"] != null && responseJSON["value"]["email"] != "") {
							setEmail(responseJSON["value"]["email"]);
                            setLoading(false);
						} else {
                            dropSession();
                        }
					});
				} else {
                    dropSession();
                }
			});
        }, dropSession);
    }, []);

    const changePhoneHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = phoneFormatter.updateValue({text: phone, cursor: phoneCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setPhone(updatedValue.text);
        setPhoneCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = phoneRef.current;
        if(input != null) {
            input.setSelectionRange(phoneCursor, phoneCursor);
        }
    }, [phoneRef, phoneCursor, phone]);

    const registerPhone = (e: React.MouseEvent<HTMLButtonElement>) => {
        doAuthenticated(async auth => {
            const unmaskedPhone = phone.length > 0 && phone[0] == "+" ? phoneFormatter.unmask(phone) : `+55${phoneFormatter.unmask(phone)}`;
            PassifyApi.requestPhoneChange(auth.accessToken, unmaskedPhone).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"]) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "confirmPhone",
                                phone: unmaskedPhone,
                                email: email,
                            }));
                            navigate("/verification");
                        }
                    });
                }
            });
        }, dropSession);
        e.preventDefault();
    }

    return loading ? <></> : <div id="register-phone">
        <div className="register-phone-prompt">Informe seu celular para concluir o cadastro:</div>
        <TextInput ref={phoneRef} id="register-phone-number" name="phone" value={phone} icon={{imageIconUrl: "/images/phone.svg", width: 16.67, height: 16.67}} placeholder="(11) 99999-9999" onChange={changePhoneHandle} />
        <MainButton enabled={phoneFormatter.unmask(phone).length >= 10} content="Cadastrar celular" onClick={registerPhone} />
    </div>;
}

export { RegisterPhoneView };