import React from "react";
import { useNavigate } from "react-router-dom";
import { PassifyApi, PhoneFormatter, doAuthenticated, executeRecaptcha, refreshAuth, setAuth } from "../../shared";
import { MainButton, TextInput } from "../../components";
import "./verificationview.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";

const VerificationView = () => {
    const navigate = useNavigate();

    const [reason, setReason] = React.useState<string>();
    const [email, setEmail] = React.useState<string>();
    const [password, setPassword] = React.useState<string>();
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [rememberMe, setRememberMe] = React.useState<boolean>(false);
    const [phone, setPhone] = React.useState<string>();
    const [code, setCode] = React.useState<string>("");

    const phoneFormatter = new PhoneFormatter();

    React.useEffect(() => {
        const rawRegisterData = sessionStorage.getItem("register");
        if(rawRegisterData == null) {
            navigate(-1);
        } else {
            const registerData = JSON.parse(rawRegisterData);
            setReason(registerData["reason"]);
            switch(registerData["reason"]) {
                case "confirmEmail":
                    setEmail(registerData["email"]);
                    setPassword(registerData["password"]);
                    setRememberMe(registerData["rememberMe"]);
                    break;
                case "resetPassword":
                    setEmail(registerData["email"]);
                    break;
                case "confirmPhone":
                    setPhone(registerData["phone"]);
                    setEmail(registerData["email"]);
                    break;
                default:
                    navigate(-1);
                    break;
            }
        }
    }, []);

    const goToRegisterPhone = () => {
        navigate("/register-phone");
    }

    const bypassPhoneConfirmation = () => {
        localStorage.setItem(`whatsappBypass-${email}`, "true");
        navigate("/ticket-selection");
    }

    const goToSignup = () => {
        navigate("/signup");
    }

    const goToForgotPassword = () => {
        navigate("/forgot-password");
    }

    const changeCodeHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const codeCandidate = e.currentTarget.value;
        if(codeCandidate.length <= 6 && !isNaN(parseInt(codeCandidate))) {
            setCode(codeCandidate);
        }
        e.preventDefault();
    }

    const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
        e.preventDefault();
    }

    const changeConfirmPasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setConfirmPassword(e.currentTarget.value);
        e.preventDefault();
    }

    const dropSession = async () => {
        navigate("/signin");
    }

    const _checkPasswordMatch = () => password == confirmPassword;
    const _checkPasswordLength = () => (password ?? "").length >= 6;
    const _checkPasswordLowercase = () => /[a-z]/.test(password ?? "");
    const _checkPasswordUppercase = () => /[A-Z]/.test(password ?? "");
    const _checkPasswordDigit = () => /[0-9]/.test(password ?? "");
    const _checkPasswordSpecialChar = () => /[!@#$%^&*(),.?":{}|<>]/.test(password ?? "");

    const openRequirements = (e: React.FormEvent<HTMLInputElement>) => {
        const elem = document.getElementById("password-requirements");
        if(elem != null) {
            elem.className = "open";
        }
        e.preventDefault();
    }

    const closeRequirements = (e: React.FormEvent<HTMLInputElement>) => {
        const elem = document.getElementById("password-requirements");
        if(elem != null) {
            elem.className = "";
        }
        e.preventDefault();
    }

    const validIcon = <FontAwesomeIcon icon={faCheck} />
    const recommendedIcon = <FontAwesomeIcon icon={faExclamation} />
    const invalidIcon = <FontAwesomeIcon icon={faX} />

    const requirement = (valid: boolean, text: string) => {
        return <div className={valid ? "requirement-valid" : "requirement-invalid"}>
            {valid ? validIcon : invalidIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    const recommendation = (valid: boolean, text: string) => {
        return <div className={valid ? "requirement-valid" : "requirement-recommended"}>
            {valid ? validIcon : recommendedIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    const signin = async () => {
        executeRecaptcha(async (token: any) => {
            const response = await PassifyApi.connect(token, email!.trim(), password!);
            if(response.status == 200) {
                const responseJSON = await response.json();
                setAuth(responseJSON["access_token"], responseJSON["refresh_token"], rememberMe);
            }
        });
    }

    const verifyEmail = async () => {
        const response = await PassifyApi.confirmEmail(email!, code, password);
        if(response.status == 200) {
            const responseJSON = await response.json();
            if(responseJSON["succeeded"]) {
                sessionStorage.removeItem("register");
                await signin();
                goToRegisterPhone();
            } else {
                window.alert("Código de verificação iválido.");
            }
        } else {
            window.alert("Código de verificação iválido.");
        }
    }

    const verifyPhone = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.changePhone(auth.accessToken, phone!, code);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    sessionStorage.removeItem("register");
                    navigate("/ticket-selection");
                } else {
                    window.alert("Código de verificação iválido.");
                }
            } else if(response.status == 401) {
                refreshAuth(verifyPhone, dropSession);
            } else {
                window.alert("Código de verificação iválido.");
            }
        }, dropSession);
    }

    const resetPassword = async () => {
        const response = await PassifyApi.resetPassword(email!, code, password!);
        if(response.status == 200) {
            const responseJSON = await response.json();
            if(responseJSON["succeeded"]) {
                sessionStorage.removeItem("register");
                await signin();
                navigate("/ticket-selection");
            } else {
                window.alert("Código de verificação iválido.");
            }
        } else {
            window.alert("Código de verificação iválido.");
        }
    }

    const verify = (e: React.MouseEvent<HTMLButtonElement>) => {
        switch(reason) {
            case "confirmEmail":
                verifyEmail();
                break;
            case "confirmPhone":
                verifyPhone();
                break;
            case "resetPassword":
                resetPassword();
                break;
        }
        e.preventDefault();
    }

    return <div id="verification">
        {phone != undefined ? <>
            <div id="verification-explanation">
                Enviamos um código para seu WhatsApp<br /><br /><b>{phoneFormatter.mask(phone)}</b><br /><small>(<a onClick={goToRegisterPhone}>Esse não é o meu número</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" value={code} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <div className="verification-button-container">
                <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
            </div>
            <div className="verification-bottom"><small><a onClick={bypassPhoneConfirmation}>Não tenho WhatsApp</a></small></div>
        </> : reason == "confirmEmail" ? <>
            <div id="verification-explanation">
                Enviamos um código para seu e-mail<br /><br /><b>{email}</b><br /><small>(<a onClick={goToSignup}>Esse não é o meu e-mail</a>)</small><br /><br />Insira-o na caixa abaixo para validá-lo:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" value={code} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <div className="verification-button-container">
                <MainButton enabled={code.length == 6} content="Verificar" onClick={verify} />
            </div>
        </> : <>
            <div id="verification-explanation">
                Enviamos um código para seu e-mail<br /><b>{email}</b><br /><br /><small>(<a onClick={reason == "confirmEmail" ? goToSignup : goToForgotPassword}>Esse não é o meu e-mail</a>)</small><br /><br />Para resetar sua senha, insira-o na caixa abaixo para validá-lo e crie uma nova senha:
            </div>
            <div className="verification-input-container">
                <div className="label">Código de verificação</div>
                <TextInput id="verification-code" name="verification-code" value={code} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000000" onChange={changeCodeHandle} />
            </div>
            <div className="verification-input-container">
                <div className="label">Senha</div>
                <TextInput id="verification-password" name="password" password={true} value={password ?? ""} icon={{imageIconUrl: "/images/password.svg", width: 14, height: 16.67}} placeholder="Senha" onChange={changePasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
            </div>
            <div className="verification-input-container">
                <div className="label">Confirme a senha</div>
                <TextInput id="verification-confirm-password" name="confirm-password" password={true} value={confirmPassword} icon={{imageIconUrl: "/images/password.svg", width: 14, height: 16.67}} placeholder="Confirme a senha" onChange={changeConfirmPasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
            </div>
            <div id="password-requirements">
                <div>Obrigatório:</div>
                {requirement(_checkPasswordLength(), "Mínimo de 6 caracteres")}
                {requirement(_checkPasswordMatch(), "Senha e confirmação de senha iguais")}
                <div><br />Recomendado:</div>
                {recommendation(_checkPasswordLowercase(), "Pelo menos uma letra minúscula")}
                {recommendation(_checkPasswordUppercase(), "Pelo menos uma letra maiúscula")}
                {recommendation(_checkPasswordDigit(), "Pelo menos um número")}
                {recommendation(_checkPasswordSpecialChar(), "Pelo menos um caractere especial (!,@,*, ...)")}
            </div>
            <div className="verification-button-container">
                <MainButton enabled={_checkPasswordLength() && _checkPasswordMatch() && code.length == 6} content="Resetar senha" onClick={verify} />
            </div>
        </>}
    </div>
}

export { VerificationView };