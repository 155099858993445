import { PassifyApi } from "../api";

export interface Auth {
    accessToken: string,
    refreshToken: string,
    rememberMe: boolean,
}

const setAuth = (accessToken: string, refreshToken: string, rememberMe: boolean) => {
    const auth: Auth = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        rememberMe: rememberMe,
    };

    sessionStorage.setItem("auth", JSON.stringify(auth));
    if(rememberMe) {
        localStorage.setItem("auth", JSON.stringify(auth));
    }

    return auth;
}

const resetAuth = () => {
        localStorage.removeItem("auth");
        sessionStorage.removeItem("auth");
}

const refreshAuth = async (onSuccess: () => void | Promise<void>, onError: () => void | Promise<void>) => {
    const auth = sessionStorage.getItem("auth");
    if(auth == null) {
        return onError();
    } else {
        const authJSON = JSON.parse(auth) as Auth;
        const response = await PassifyApi.refreshToken(authJSON.refreshToken);
        if(response.status == 200) {
            const responseJSON = await response.json();
            setAuth(responseJSON["access_token"], responseJSON["refresh_token"], authJSON.rememberMe);
            return onSuccess();
        } else {
            resetAuth();
            return onError();
        }
    }
}

const doAuthenticated = (onAuthenticated: (auth: Auth) => void | Promise<void>, onNotAuthenticated: () => void | Promise<void>) => {
    const auth = sessionStorage.getItem("auth");
    if(auth == null) {
        return onNotAuthenticated();
    } else {
        const authJSON = JSON.parse(auth) as Auth;
        return onAuthenticated(authJSON);
    }
}



export { setAuth, resetAuth, refreshAuth, doAuthenticated };