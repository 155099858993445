import React from "react";
import Web3 from "web3";
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from "react-router-dom";
import { PassifyApi, doAuthenticated, refreshAuth } from "../../shared";
import "./passview.scss"
import { CheckboxInput, SmallButton } from "../../components";

interface SignedMessage {
    message: string,
    signature: string
}

interface PassProps {
}

const PassView = (props: PassProps) => {
    const navigate = useNavigate();

    const [dismissDisclaimer, setDismissDisclaimer] = React.useState<boolean>(true);
    const [dontShowAgain, setDontShowAgain] = React.useState<boolean>(false);
    const [intervalProgress, setIntervalProgress] = React.useState<number>(0);
    const [signedMsg, setSignedMsg] = React.useState<SignedMessage>({message: "", signature: ""});

    const startTime = Date.now();
    const web3 = new Web3("https://polygon-rpc.com");
    const intervalSize = 5000;

    let timer: NodeJS.Timer;
    let nextSignedMsg: SignedMessage;
    let actualSignedMsg: SignedMessage;
    let actualIntervalProgress: number;
    let isLoading = false;

    const dropSession = async () => {
        navigate("/signin");
    }

    React.useEffect(() => {
        doAuthenticated(async auth => {
            PassifyApi.getCustomerData(auth.accessToken).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"] && (responseJSON["value"]["email"] == null || responseJSON["value"]["email"] == "")) {
                            dropSession();
                        }
                    });
                } else if(response.status == 401) {
                    dropSession();
                }
            });
        }, dropSession);

        const dismissPassDisclaimer = localStorage.getItem("dismissPassDisclaimer");
        if(dismissPassDisclaimer == null) {
            setDismissDisclaimer(false);
        }

        preLoad();
        if(timer != undefined) {
            clearInterval(timer);
        }
        timer = setInterval(() => { generatePass(); }, 10);

        return () => {
            if(timer != undefined) {
                clearInterval(timer);
            }
        }
    }, []);

    const account = () => {
        let account;
        let privateKey = localStorage.getItem("account");
        if(!privateKey) {
            account = web3.eth.accounts.create();
            localStorage.setItem("account", account.privateKey);
        } else {
            account = web3.eth.accounts.privateKeyToAccount(privateKey);
        }
        web3.eth.accounts.wallet.add(account);
        return account;
    };

    const preLoad = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getPassSessionMessage(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    const request = responseJSON["value"];
                    nextSignedMsg = {
                        message: request,
                        signature: await web3.eth.sign(request, account().address),
                    };
                    isLoading = false;
                }
            } else if(response.status == 401) {
                await refreshAuth(preLoad, dropSession);
            }
        }, dropSession);
    }

    const generatePass = () => {
        const newIntervalProgress = ((Date.now() - startTime) % intervalSize) / intervalSize;

        if(actualSignedMsg == null || actualSignedMsg.message == "") {
            actualSignedMsg = nextSignedMsg;
            setSignedMsg(actualSignedMsg);
        }

        if(newIntervalProgress > 0.9 && !isLoading && actualSignedMsg.message == nextSignedMsg.message) {
            isLoading = true;
            preLoad();
        }

        if(newIntervalProgress < actualIntervalProgress) {
            if(nextSignedMsg && actualSignedMsg.message != nextSignedMsg.message) {
                actualSignedMsg = nextSignedMsg;
                setSignedMsg(actualSignedMsg);
                actualIntervalProgress = newIntervalProgress;
                setIntervalProgress(actualIntervalProgress);
            }
        } else {
            actualIntervalProgress = newIntervalProgress;
            setIntervalProgress(actualIntervalProgress);
        }
    };

    const clickDontShowAgainHandle = (e: React.FormEvent<HTMLInputElement>) => {
		setDontShowAgain(oldDontShowAgain => !oldDontShowAgain);
		e.preventDefault();
  	}

    const dismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(dontShowAgain) {
            localStorage.setItem("dismissPassDisclaimer", "true");
        }
        setDismissDisclaimer(true);
        e.preventDefault();
    }

    return (
        <div id="pass">
            {!dismissDisclaimer && <div id="pass-disclaimer">
                <div id="pass-disclaimer-container">
                    <div><b>Bem vindo ao passe Passify!</b></div>
                    <div>Esse é o seu passe único para todos os ingressos que você tem na sua carteira. Com ele você não precisa mais buscar o QR Code de um ingresso específico em sua carteira, desde que você tenha esse ingresso. Para saber quais ingressos você possui vá na sua carteira.</div>
                    <div><CheckboxInput id="pass-dont-show-again" name="dont-show-again" checked={dontShowAgain} text="Não mostrar denovo" onClick={clickDontShowAgainHandle} /></div>
                    <div><SmallButton content="Ok, entendi!" onClick={dismiss} /></div>
                </div>
            </div>}
            <div className="qr-code-holder">
                {signedMsg && signedMsg.message != "" && <>
                    <QRCodeSVG className="qr-code" fgColor="#f8f5ff" bgColor="#851fb9" value={JSON.stringify(signedMsg)} imageSettings={{
                        src: "/images/retro-logo.png",
                        x: undefined,
                        y: undefined,
                        width: 40,
                        height: 18,
                        excavate: true,
                    }} />
                    {intervalProgress !== -1 && 
                        <div className="qr-code-progressbar-container">
                            <div className="qr-code-progressbar" style={{width: `${intervalProgress * 286}px`}}></div>
                        </div>
                    }
                </>}
                <div className="passify-logo">
                    <img src="/images/passify-dark.svg" width={64} />
                </div>
            </div>
        </div>
    );
};

export { PassView };