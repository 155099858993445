import React from "react";
import { EmailFormatter, PassifyApi, Reservation, doAuthenticated, refreshAuth } from "../../shared";
import { useNavigate } from "react-router-dom";
import { MainButton, SmallButton, TextInput } from "../../components";
import "./reviewsummaryview.scss";

interface ReviewSummaryProps {
    eventSessionId: string,
}

const ReviewSummaryView = (props: ReviewSummaryProps) => {
    const navigate = useNavigate();

    const [feeRate, setFeeRate] = React.useState<number>(0.1);
    const [customerEmail, setCustomerEmail] = React.useState<string>("");
    const [reservations, setReservations] = React.useState<Array<Reservation>>([]);

    const emailFormatter = new EmailFormatter();
    const [email, setEmail] = React.useState<string>("");
    const [emailCursor, setEmailCursor] = React.useState<number>(0);
    const emailRef = React.useRef<HTMLInputElement>(null);
    const [confirmEmail, setConfirmEmail] = React.useState<string>("");
    const [confirmEmailCursor, setConfirmEmailCursor] = React.useState<number>(0);
    const confirmEmailRef = React.useRef<HTMLInputElement>(null);
    
    let orderId: string;
    let actualReservations: Array<Reservation> = [];

    const dropSession = async () => {
        navigate("/signin");
    }

    const getCustomerData = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    setCustomerEmail(responseJSON["value"]["email"] || "");
                }
            } else if(response.status == 401) {
                await refreshAuth(getCustomerData, dropSession);
            }
        }, dropSession);
    }

    const loadReservations = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getReservations(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    actualReservations = responseJSON["value"];
                    setReservations(actualReservations);
                }
            } else if(response.status == 401) {
                refreshAuth(loadReservations, dropSession);
            }
        }, dropSession);
    }

    React.useEffect(() => {
        getCustomerData().then(() => {
            loadReservations().then(() => {
                if(actualReservations.length == 0) {
                    navigate("/ticket-selection");
                }
            });
        });
    }, []);

    const subtotal = reservations.reduce((prev, cur) => prev + cur.quantity * cur.batch.price, 0);
    const feeAmount = feeRate * subtotal;

    const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setEmail(updatedValue.text);
        setEmailCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = emailRef.current;
        if(input != null) {
            input.setSelectionRange(emailCursor, emailCursor);
        }
    }, [emailRef, emailCursor, email]);

    const changeConfirmEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = emailFormatter.updateValue({text: confirmEmail, cursor: confirmEmailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setConfirmEmail(updatedValue.text);
        setConfirmEmailCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = confirmEmailRef.current;
        if(input != null) {
            input.setSelectionRange(confirmEmailCursor, confirmEmailCursor);
        }
    }, [confirmEmailRef, confirmEmailCursor, confirmEmail]);
    
    const createOrder = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.createCheckoutOrder(auth.accessToken, reservations.map(reservation => reservation.id), email);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    orderId = responseJSON["value"]["id"];
                }
            } else if(response.status == 401) {
                refreshAuth(createOrder, dropSession);
            }
        }, dropSession);
    }

    const payWithPix = (e: React.MouseEvent<HTMLButtonElement>) => {
        createOrder().then(() => {
            navigate(`/payment/${orderId}/pix`);
        });
        e.preventDefault();
    }

    const payWithCard = (e: React.MouseEvent<HTMLButtonElement>) => {
        createOrder().then(() => {
            navigate(`/payment/${orderId}/card`);
        });
        e.preventDefault();
    }

    return <div id="review-summary">
        <div id="review-summary-table">
            {reservations.map(reservation => <div className="review-summary-item">
                <div>{reservation.quantity}x {reservation.batch.name}</div>
                <div>R$ {(reservation.quantity * reservation.batch.price).toFixed(2)}</div>
            </div>)}
            <div className="review-summary-item">
                <div>Taxa de serviço</div>
                <div>R$ {feeAmount.toFixed(2)}</div>
            </div>
            <div id="review-summary-total" className="review-summary-item">
                <div>Total</div>
                <div>R$ {(subtotal + feeAmount).toFixed(2)}</div>
            </div>
        </div>
        {customerEmail == "" && <>
            <div className="unsigned-title">Você não está logado na Passify!<br />Para prosseguir para o pagamento, informe seu e-mail:</div>
            <div className="unsigned-form-field">
                <div className="label">E-mail</div>
                <TextInput ref={emailRef} id="unsigned-email" name="email" value={email} icon={{imageIconUrl: "/images/email.svg", width: 16.67, height: 15}} placeholder="Seu e-mail" onChange={changeEmailHandle} />
            </div>
            <div className="unsigned-form-field">
                <div className="label">Confirme seu E-mail</div>
                <TextInput ref={confirmEmailRef} id="unsigned-confirm-email" name="confirm-email" value={confirmEmail} icon={{imageIconUrl: "/images/email.svg", width: 16.67, height: 15}} placeholder="Confirme seu e-mail" onChange={changeConfirmEmailHandle} />
            </div>
        </>}
        <div className="payment-button-container">
            <SmallButton enabled={customerEmail != "" || (email != "" && email == confirmEmail)} content="PIX" onClick={payWithPix} />
            <SmallButton enabled={customerEmail != "" || (email != "" && email == confirmEmail)} content="Cartão de Crédito" onClick={payWithCard} />
        </div>
        <div className="passify-logo">
            <img src="/images/passify-dark.svg" width={64} />
        </div>
    </div>;
};

export { ReviewSummaryView };