import React from "react";
import { useNavigate } from "react-router-dom";
import { PassifyApi, doAuthenticated, refreshAuth } from "../../shared";
import "./walletview.scss";
import { SmallButton } from "../../components";

const WalletView = () => {
    const navigate = useNavigate();

    const [passes, setPasses] = React.useState<Array<any>>([]);

    const dropSession = async () => {
        navigate("/signin");
    }

    const loadPasses = async () => {
        doAuthenticated(async auth => {
            const response = await PassifyApi.getPasses(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    setPasses((responseJSON["value"] as Array<any>).filter(pass => ['5a248954-c0a5-462c-b3c8-c94a3446e159','8e690381-e8a7-4f43-b10d-67bc6211f777'].indexOf(pass.event.id) != -1));
                }
            } else if(response.status == 401) {
                refreshAuth(loadPasses, dropSession);
            }
        }, dropSession);
    }

    React.useEffect(() => {
		doAuthenticated(async auth => {
			PassifyApi.getCustomerData(auth.accessToken).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						if(responseJSON["succeeded"] && (responseJSON["value"]["email"] == null || responseJSON["value"]["email"] == "")) {
							navigate("/signin");
						}
					});
				}
			});
		}, () => {});

        loadPasses();
    }, []);

    const passesGroupedByBatch = () => {
        const groupedPasses: Array<any> = [];
        for(let i = 0; i < passes.length; i++) {
            const pass = passes[i];
            if(groupedPasses.find(groupedPass => groupedPass.batch.id == pass.batch.id) == undefined) {
                groupedPasses.push(pass);
            }
        }
        return groupedPasses;
    }

    const goToPass = (e: React.MouseEvent<HTMLDivElement>) => {
        navigate("/pass");
        e.preventDefault();
    }
    
    return <div id="wallet">
        {passes.length > 0 ? <div id="wallet-grid">
            {passesGroupedByBatch().map(pass => <div key={pass.batch.id} className="wallet-pass-container">
                <div className={`wallet-pass ${pass.isUsed ? "used" : ""}`} onClick={pass.isUsed ? undefined : goToPass}>
                    <div className="wallet-pass-event-image"><img src={pass.event.smallImageUrl} /></div>
                    <div className="wallet-pass-event-name">{pass.event.name}</div>
                    <div className="wallet-pass-batch-name">{pass.batch.name}</div>
                    <div className="wallet-pass-quantity">{passes.reduce((prev, cur) => prev + (cur.batch.id == pass.batch.id ? 1 : 0), 0)}x</div>
                </div>
                <div className="transfer-button-container">
                    <SmallButton enabled={false} content="Transferir" onClick={() => {}} />
                </div>
            </div>)}
        </div> : <div className="wallet-prompt">Nenhum ingresso em sua carteira.</div>}
    </div>;
};

export { WalletView };