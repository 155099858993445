import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainButton, SecondaryButton, TextInput } from "../../components";
import { CardExpirationFormatter, CardNumberFormatter, CpfFormatter, MercadoPagoApi, PassifyApi, doAuthenticated, refreshAuth } from "../../shared";
import "./paymentview.scss";
import { QRCodeSVG } from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

enum PaymentType {
    card,
    pix,
}

const PaymentView = () => {
    const navigate = useNavigate();
    const {orderId, paymentTypeParam} = useParams();

    const [paymentType, setPaymentType] = React.useState<PaymentType>();
    const [invoiceCode, setInvoiceCode] = React.useState<string>();
    const [copied, setCopied] = React.useState<boolean>(false);

    const [name, setName] = React.useState<string>("");
    const nameRef = React.useRef<HTMLInputElement>(null);

    const [cpf, setCpf] = React.useState<string>("");
    const [cpfCursor, setCpfCursor] = React.useState<number>(0);
    const cpfRef = React.useRef<HTMLInputElement>(null);
    const cpfFormatter = new CpfFormatter();

    const [cardBrand, setCardBrand] = React.useState<string>("");
    const [cardNumber, setCardNumber] = React.useState<string>("");
    const [cardNumberCursor, setCardNumberCursor] = React.useState<number>(0);
    const cardNumberRef = React.useRef<HTMLInputElement>(null);
    const cardNumberFormatter = new CardNumberFormatter();

    const [cardExpiration, setCardExpiration] = React.useState<string>("");
    const [cardExpirationCursor, setCardExpirationCursor] = React.useState<number>(0);
    const cardExpirationRef = React.useRef<HTMLInputElement>(null);
    const cardExpirationFormatter = new CardExpirationFormatter();

    const [cvv, setCvv] = React.useState<string>("");
    const cvvRef = React.useRef<HTMLInputElement>(null);

    let timer: NodeJS.Timer;

    const dropSession = async () => {
        navigate("/signin");
    }

    const getCustomerData = async () => {
        await doAuthenticated(async auth => {
            const response = await PassifyApi.getCustomerData(auth.accessToken);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    setName(responseJSON["value"]["name"] || "");
                    setCpf(cpfFormatter.mask(responseJSON["value"]["taxId"]) || "");
                }
            } else if(response.status == 401) {
                await refreshAuth(getCustomerData, dropSession);
            }
        }, dropSession);
    }

    const processOrderStatus = (order: any) => {
        const orderStatus = order["status"];
        const rejectionDetails = order["rejectionDetails"];

        switch(orderStatus) {
            case "PaymentApproved":
            case "Completed":
                window.alert("Pagamento aprovado! Seu passe está na sua carteira.");
                if(timer != undefined) {
                    clearInterval(timer);
                }
                navigate("/cyberpunk");
                break;
            case "PendingPayment":
                if(paymentType == PaymentType.card) {
                    window.alert("Seu pagamento está processando, em alguns momentos iremos confirmá-lo.");
                }
                break;
            case "PaymentRejected":
                let rejectionMessage;
                switch(rejectionDetails) {
                    case "CardRejectedBadExpirationDate":
                        rejectionMessage = "Data de validade do cartão está incorreta.";
                        break;
                    case "CardRejectedBadDetails":
                        rejectionMessage = "Informações do cartão estão incorretas.";
                        break;
                    case "CardRejectedBadCvv":
                        rejectionMessage = "Código de segurança do cartão está incorreto.";
                        break;
                    case "CardRejectedBlackList":
                        rejectionMessage = "Este cartão consta em uma lista de fraudes/golpes.";
                        break;
                    case "CardRejectedCallForAuthorize":
                        rejectionMessage = "Esta operação precisa de autorização do seu banco, contate-o para resolver o problema e tente novamente.";
                        break;
                    case "CardRejectedDisabled":
                        rejectionMessage = "Este cartão está desativado.";
                        break;
                    case "CardRejectedDuplicatedPayment":
                        rejectionMessage = "Operação duplicada.";
                        break;
                    case "CardRejectedHighRisk":
                        rejectionMessage = "Sistema anti-fraude rejeitou o pagamento.";
                        break;
                    case "CardRejectedInsufficientAmount":
                        rejectionMessage = "O cartão não possui limite para a operação.";
                        break;
                    case "CardRejectedInvalidInstallments":
                        rejectionMessage = "Número de parcelas inválido.";
                        break;
                    case "CardRejectedMaxAttempts":
                        rejectionMessage = "Número de tentativas máximo atingido.";
                        break;
                    default:
                        rejectionMessage = "Verifique com seu banco o motivo.";
                        break;
                }
                window.alert(`Seu pagamento foi rejeitado. ${rejectionMessage} Qualquer dúvida entre em contato conosco pelo WhatsApp: (11) 95662-1753.`);
                break;
        }
    }

    React.useEffect(() => {
        if(orderId == undefined) {
            navigate(-1);
        }

        getCustomerData();
        if(paymentTypeParam != undefined) {
            switch(paymentTypeParam) {
                case "pix":
                    setPaymentType(PaymentType.pix);
                    break;
                case "card":
                    setPaymentType(PaymentType.card);
                    break;
            }
        }

        timer = setInterval(() => {
            doAuthenticated(async auth => {
                PassifyApi.getCheckoutOrder(auth.accessToken, orderId!).then(response => {
                    if(response.status == 200) {
                        response.json().then(responseJSON => {
                            if(responseJSON["succeeded"]) {
                                processOrderStatus(responseJSON["value"]);
                            }
                        });
                    } else if(response.status == 401) {
                        refreshAuth(() => {}, dropSession);
                    }
                });
            }, dropSession);
        }, 1500);

        return () => {
            if(timer != undefined) {
                clearInterval(timer);
            }
        }
    }, []);

    const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
        e.preventDefault();
    }

    const changeCpfHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = cpfFormatter.updateValue({text: cpf, cursor: cpfCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setCpf(updatedValue.text);
        setCpfCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = cpfRef.current;
        if(input != null) {
            input.setSelectionRange(cpfCursor, cpfCursor);
        }
    }, [cpfRef, cpfCursor, cpf]);

    const changeCardNumberHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = cardNumberFormatter.updateValue({text: cardNumber, cursor: cardNumberCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        const unmaskedCardNumber = cardNumberFormatter.unmask(updatedValue.text);
        if(unmaskedCardNumber.length >= 6) {
            MercadoPagoApi.getPaymentMethods(unmaskedCardNumber).then((response) => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        setCardBrand(responseJSON["results"] != null && responseJSON["results"].length > 0 ? responseJSON["results"][0]["id"] : "");
                    });
                } else {
                  setCardBrand("");
                }
            });
        } else {
            setCardBrand("");
        }
        setCardNumber(updatedValue.text);
        setCardNumberCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = cardNumberRef.current;
        if(input != null) {
            input.setSelectionRange(cardNumberCursor, cardNumberCursor);
        }
    }, [cardNumberRef, cardNumberCursor, cardNumber]);

    const changeCardExpirationHandle = (e: React.FormEvent<HTMLInputElement>) => {
        const updatedValue = cardExpirationFormatter.updateValue({text: cardExpiration, cursor: cardExpirationCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
        setCardExpiration(updatedValue.text);
        setCardExpirationCursor(updatedValue.cursor);
        e.preventDefault();
    }

    React.useEffect(() => {
        const input = cardExpirationRef.current;
        if(input != null) {
            input.setSelectionRange(cardExpirationCursor, cardExpirationCursor);
        }
    }, [cardExpirationRef, cardExpirationCursor, cardExpiration]);

    const changeCvvHandle = (e: React.FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length <= 3 && !isNaN(parseInt(e.currentTarget.value))) {
            setCvv(e.currentTarget.value);
        }
        e.preventDefault();
    }

    const selectPix = (e: React.MouseEvent<HTMLButtonElement>) => {
        setPaymentType(PaymentType.pix);
        e.preventDefault();
    }

    const selectCard = (e: React.MouseEvent<HTMLButtonElement>) => {
        setPaymentType(PaymentType.card);
        e.preventDefault();
    }

    const getPaymentToken = async () => {
        const exp = cardExpiration.split("/");
        const response = await MercadoPagoApi.createCardToken(
            cardNumberFormatter.unmask(cardNumber),
            name,
            "CPF",
            cpfFormatter.unmask(cpf),
            cvv,
            exp[0],
            `20${exp[1]}`
        );
        if(response.ok) {
            const responseJSON = await response.json();
            console.log(responseJSON);
            return responseJSON["id"];
        }
        return "";
    }

    const prepareOrderAndPay = (e: React.MouseEvent<HTMLButtonElement>) => {
        getPaymentToken().then(paymentToken => {
            doAuthenticated(async auth => {
                PassifyApi.chooseCardPayment(auth.accessToken, orderId!, cpfFormatter.unmask(cpf), paymentToken, cardBrand).then(response => {
                    if(response.status == 200) {
                        response.json().then(responseJSON => {
                            if(!responseJSON["succeeded"]) {
                                window.alert(`Pagamento não foi processado. (Erro: ${JSON.stringify(responseJSON["errors"])})`);
                            }
                        });
                    } else if(response.status == 401) {
                        refreshAuth(() => { prepareOrderAndPay(e); }, dropSession);
                    }
                });
            }, dropSession);
        });
        e.preventDefault();
    }

    const choosePixPayment = () => {
        doAuthenticated(async auth => {
            const response = await PassifyApi.choosePixPayment(auth.accessToken, orderId!, cpfFormatter.unmask(cpf), name);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    setInvoiceCode(responseJSON["value"]["invoiceCode"]);
                }
            } else if(response.status == 401) {
                refreshAuth(choosePixPayment, dropSession);
            }
        }, dropSession);
    }

    const generatePix = (e: React.MouseEvent<HTMLButtonElement>) => {
        choosePixPayment();
        e.preventDefault();
    }

    const copyPix = (e: React.MouseEvent<HTMLDivElement>) => {
        navigator.clipboard.writeText(invoiceCode ?? "");
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
        e.preventDefault();
    }

    return <div id="payment">
        {paymentType == undefined ? <div id="payment-choose">
            Pagar com:
            <SecondaryButton content="PIX (-50% na taxa)" onClick={selectPix} />
            <SecondaryButton content="Cartão de crédito" onClick={selectCard} />
        </div> : <form id="payment-form">
            {paymentType == PaymentType.card && <>
                <div className="payment-form-field">
                    <div className="label">Titular do cartão</div>
                    <TextInput ref={nameRef} id="payment-name" name="name" value={name} icon={{imageIconUrl: "/images/user.svg", width: 16.67, height: 19}} placeholder="Seu nome" onChange={changeNameHandle} />
                </div>
                <div className="payment-form-field">
                    <div className="label">CPF do titular</div>
                    <TextInput ref={cpfRef} id="payment-cpf" name="cpf" value={cpf} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000.000.000-00" onChange={changeCpfHandle} />
                </div>
                <div className="payment-form-field">
                    <div className="label">Número do cartão</div>
                    <TextInput ref={cardNumberRef} id="payment-card-number" name="cardNumber" value={cardNumber} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} suffix={cardBrand != "" ? <img src={`https://passify.co/${cardBrand}.svg`} width={30} /> : undefined} placeholder="0000 0000 0000 0000" onChange={changeCardNumberHandle} />
                </div>
                <div className="payment-form-row">
                    <div className="payment-form-field">
                        <div className="label">Data de validade</div>
                        <TextInput ref={cardExpirationRef} id="payment-card-expiration" name="card-expiration" value={cardExpiration} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="MM/AA" onChange={changeCardExpirationHandle} />
                    </div>
                    <div className="payment-form-field">
                        <div className="label">CVV</div>
                        <TextInput ref={cvvRef} id="payment-cvv" name="cvv" password={true} value={cvv} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="" onChange={changeCvvHandle} />
                    </div>
                </div>
                <div className="pay-button-container">
                    <MainButton content="Pagar" onClick={prepareOrderAndPay} />
                </div>
            </>}
            {paymentType == PaymentType.pix && <>
                {invoiceCode == undefined ? <>
                    <div className="payment-form-field">
                        <div className="label">Name</div>
                        <TextInput ref={nameRef} id="payment-name" name="name" value={name} icon={{imageIconUrl: "/images/user.svg", width: 16.67, height: 19}} placeholder="Seu nome" onChange={changeNameHandle} />
                    </div>
                    <div className="payment-form-field">
                        <div className="label">CPF do pagador</div>
                        <TextInput ref={cpfRef} id="payment-cpf" name="cpf" value={cpf} icon={{imageIconUrl: "/images/id-card.svg", width: 16.67, height: 15}} placeholder="000.000.000-00" onChange={changeCpfHandle} />
                    </div>
                    <div className="pay-button-container">
                        <MainButton content="Gerar PIX" onClick={generatePix} />
                    </div>
                </> : <>
                    <div className="qr-code-explain">
                        Para obter seu ingresso basta pagar o PIX abaixo a partir de uma conta cujo CPF do titular seja<br /><br /><b>{cpf}</b><br /><small>(<a href="#" onClick={(e) => { setInvoiceCode(undefined); e.preventDefault(); }}>Este não é o CPF do pagador</a>)</small>.
                    </div>
                    <div className="qr-code-holder">
                        <QRCodeSVG className="qr-code" fgColor="#f8f5ff" bgColor="#851fb9" value={invoiceCode} />
                        <div className="qr-code-copy" onClick={copyPix}><FontAwesomeIcon icon={faCopy} /> {copied ? "Código PIX copiado!" : "Copiar código PIX"}</div>
                    </div>
                </>}
            </>}
        </form>}
        <div className="passify-logo">
            <img src="/images/passify-dark.svg" width={64} />
        </div>
    </div>;
}

export { PaymentView };