class MercadoPagoApi {
    private static _host = "https://api.mercadopago.com/v1";
    private static _publicKey = "APP_USR-d00b823e-70f0-4b3c-bce5-a11e4683a4e3"; // PROD
    //private static _publicKey = "TEST-62ad870e-e4c7-46b6-92eb-bd90647eb55c"; // TEST

    private static _get = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "GET"
        });
    }

    private static _post = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "POST"
        });
    }

    private static _put = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "PUT"
        });
    }

    private static _delete = (url: string, init?: RequestInit) => {
        return fetch(`${this._host}${url}`, {
            ...init,
            method: "DELETE"
        });
    }

    static async initial() {
        return await this._get(`/payment_methods/search?publick_key=${this._publicKey}&locale=pt-BR&js_version=2.0.0&referer=&marketplace=NONE&status=active&limit=1&site_id=MLB`);
    }
    
    static async getPaymentMethods(cardBin: string) {
        return await this._get(`/payment_methods/search?publick_key=${this._publicKey}&locale=pt-BR&js_version=2.0.0&referer=&marketplace=NONE&status=active&bins=${cardBin.toString()}&processing_mode=aggregator&site_id=MLB`);
    }
    
    static async getInstallments(cardBin: string, amount: number) {
        return await this._get(`/payment_methods/installments?publick_key=${this._publicKey}&locale=pt-BR&js_version=2.0.0&referer=&bin=${cardBin.toString()}&processing_mode=aggregator&payment_type_id=credit_card&amount=${amount.toFixed(2)}`);
    }
    
    static async createCardToken(
        cardNumber: string,
        cardHolderName: string,
        cardHolderIdType: string,
        cardHolderId: string,
        securityCode: string,
        expirationMonth: string,
        expirationYear: string
    ) {
        return await this._post(`/card_tokens?public_key=${this._publicKey}&locale=pt-BR&js_version=2.0.0&referer=`, {
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({
            "public_key": this._publicKey,
            "card_number": cardNumber,
            "cardholder": {
              "name": cardHolderName,
              "identification": {
                "type": cardHolderIdType,
                "number": cardHolderId,
              }
            },
            "security_code": securityCode,
            "expiration_month": expirationMonth,
            "expiration_year": expirationYear,
            "device": {
              "meli": {
                "session_id":
                    "armor.9106781a2489d3e3368771f63fceab4be4d1066e4457e7bb9db4184b4ffc4549d0ba4dc1dd40909b44a864ee017e6cfb0de53a53a458a2731de568cae7bdffc2a85dc54404d362bef6db7184ee5de4d92c06d3c71a0414365a125f22530862d8.aebe0e87f849be88d94fa1bd8eab45e2"
              }
            }
          }),
        });
    }
}

export { MercadoPagoApi };