import { MaskFormatter } from "./maskformatter";

class CardNumberFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replaceAll(/[ ]/g, "");
    }
    
    mask(unmaskedText: string): string | null {
        if(unmaskedText == "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
        }

        match = unmaskedText.match(/^([0-9]{4})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `${match[1]} ${match[2]} ${match[3]}`;
        }

        match = unmaskedText.match(/^([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `${match[1]} ${match[2]}`;
        }

        match = unmaskedText.match(/^([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `${match[1]}`;
        }

        return null;
    }
}

export { CardNumberFormatter };