import React from "react";
import { PassifyApi, doAuthenticated } from "../../shared";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./createpasswordview.scss";
import { CheckboxInput, MainButton, TextInput } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation, faX } from "@fortawesome/free-solid-svg-icons";

const CreatePasswordView = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [agreeOnTerms, setAgreeOnTerms] = React.useState<boolean>(false);

    React.useEffect(() => {
		doAuthenticated(async auth => {
			PassifyApi.getCustomerData(auth.accessToken).then(response => {
				if(response.status == 200) {
					response.json().then(responseJSON => {
						if(responseJSON["succeeded"] && responseJSON["value"]["email"] != null && responseJSON["value"]["email"] != "") {
							navigate("/ticket-selection");
						}
					});
				}
			});
		}, () => {});
    }, []);

    const _checkPasswordMatch = () => password == confirmPassword;
    const _checkPasswordLength = () => password.length >= 6;
    const _checkPasswordLowercase = () => /[a-z]/.test(password);
    const _checkPasswordUppercase = () => /[A-Z]/.test(password);
    const _checkPasswordDigit = () => /[0-9]/.test(password);
    const _checkPasswordSpecialChar = () => /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const changePasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value);
        e.preventDefault();
    };

    const changeConfirmPasswordHandle = (e: React.FormEvent<HTMLInputElement>) => {
        setConfirmPassword(e.currentTarget.value);
        e.preventDefault();
    };

    const clickAgreeOnTermsHandle = (e: React.FormEvent<HTMLInputElement>) => {
		setAgreeOnTerms(oldAgreeOnTerms => !oldAgreeOnTerms);
		e.preventDefault();
  	}

      const createPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(_checkPasswordLength() && _checkPasswordMatch() && email.trim() != "") {
            /*PassifyApi.confirmEmail(email!, code!, password).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"]) {
                            sessionStorage.removeItem("register");
                            //await signin();
                            navigate("/register-phone");
                        } else {
                            window.alert("Código de verificação iválido.");
                        }
                    });
                } else {
                    window.alert("Código de verificação iválido.");
                }
            });*/

            PassifyApi.register(email.trim(), password).then(response => {
                if(response.status == 200) {
                    response.json().then(responseJSON => {
                        if(responseJSON["succeeded"] == true) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "confirmEmail",
                                email: email.trim(),
                                password: password,
                                rememberMe: true,
                            }));
                            navigate("/verification");
                        }
                    });
                }
            });
        }
        e.preventDefault();
    };

    const openRequirements = (e: React.FormEvent<HTMLInputElement>) => {
        const elem = document.getElementById("password-requirements");
        if(elem != null) {
            elem.className = "open";
        }
        e.preventDefault();
    }

    const closeRequirements = (e: React.FormEvent<HTMLInputElement>) => {
        const elem = document.getElementById("password-requirements");
        if(elem != null) {
            elem.className = "";
        }
        e.preventDefault();
    }

    const validIcon = <FontAwesomeIcon icon={faCheck} />
    const recommendedIcon = <FontAwesomeIcon icon={faExclamation} />
    const invalidIcon = <FontAwesomeIcon icon={faX} />

    const requirement = (valid: boolean, text: string) => {
        return <div className={valid ? "requirement-valid" : "requirement-invalid"}>
            {valid ? validIcon : invalidIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    const recommendation = (valid: boolean, text: string) => {
        return <div className={valid ? "requirement-valid" : "requirement-recommended"}>
            {valid ? validIcon : recommendedIcon}
            <div className="requirement-text">{text}</div>
        </div>;
    }

    return <div id="create-password">
        <div className="create-password-prompt">Cadastre uma senha para concluir o seu cadastro e acessar seu passe:</div>
        <TextInput id="create-password-password" name="password" password={true} value={password} icon={{imageIconUrl: "/images/password.svg", width: 14, height: 16.67}} placeholder="Senha" onChange={changePasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
        <TextInput id="create-password-confirm-password" name="confirm-password" password={true} value={confirmPassword} icon={{imageIconUrl: "/images/password.svg", width: 14, height: 16.67}} placeholder="Confirme a senha" onChange={changeConfirmPasswordHandle} onFocus={openRequirements} onBlur={closeRequirements} />
        <div id="password-requirements">
            <div>Obrigatório:</div>
            {requirement(_checkPasswordLength(), "Mínimo de 6 caracteres")}
            {requirement(_checkPasswordMatch(), "Senha e confirmação de senha iguais")}
            <div><br />Recomendado:</div>
            {recommendation(_checkPasswordLowercase(), "Pelo menos uma letra minúscula")}
            {recommendation(_checkPasswordUppercase(), "Pelo menos uma letra maiúscula")}
            {recommendation(_checkPasswordDigit(), "Pelo menos um número")}
            {recommendation(_checkPasswordSpecialChar(), "Pelo menos um caractere especial (!,@,*, ...)")}
        </div>
        <div className="terms"><CheckboxInput id="signin-agree-on-terms" name="agree-on-terms" checked={agreeOnTerms} text="" onClick={clickAgreeOnTermsHandle} /><div>Eu aceito a <a href="https://passify.co/politica-de-privacidade.pdf" target="_blank">política de privacidade</a> e os <a href="https://passify.co/termos-de-servico.pdf" target="_blank">termos de serviço</a> da Passify</div></div>
        <MainButton content="Concluir cadastro" onClick={createPassword} />
    </div>;
}

export { CreatePasswordView };