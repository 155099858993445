import { MaskFormatter } from "./maskformatter";

class CpfFormatter extends MaskFormatter {
    unmask(maskedText: string): string {
        return maskedText.replaceAll(/[\.-]/g, "");
    }

    mask(unmaskedText: string): string | null {
        if(unmaskedText == "") {
            return unmaskedText;
        }

        let match;

        match = unmaskedText.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
        }

        match = unmaskedText.match(/^([0-9]{3})([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `${match[1]}.${match[2]}.${match[3]}`;
        }

        match = unmaskedText.match(/^([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `${match[1]}.${match[2]}`;
        }

        match = unmaskedText.match(/^([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `${match[1]}`;
        }

        return null;
    }
}

export { CpfFormatter };