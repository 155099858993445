import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { App } from './app';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CreatePasswordView, CyberpunkView, ForgotPasswordView, HomeView, PassView, PaymentView, ProfileView, RegisterPhoneView, ReviewSummaryView, SigninView, SignoutView, SignupView, TicketSelectionView, VerificationView, WalletView } from './views';
import { initFacebookSdk, initRecaptcha } from './shared';
import './index.scss';

const eventId = "5a248954-c0a5-462c-b3c8-c94a3446e159";
const eventSessionId = "95d61f4e-bfa2-4338-9828-08654994db11"; // PROD

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function startApp() {
  root.render(
    <GoogleOAuthProvider clientId="528737513271-sujbe12bafme3mer389k3ees5ohn963s.apps.googleusercontent.com">
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route index element={<HomeView />} />
              <Route path="ticket-selection" element={<TicketSelectionView eventSessionId={eventSessionId} />} />
              <Route path="review-summary" element={<ReviewSummaryView eventSessionId={eventSessionId} />} />
              <Route path="payment/:orderId">
                <Route index element={<PaymentView />} />
                <Route path=":paymentTypeParam" element={<PaymentView />} />
                <Route path="*" element={<PaymentView />} />
              </Route>
              <Route path="cyberpunk" element={<CyberpunkView />} />
              <Route path="pass" element={<PassView />} />
              <Route path="wallet" element={<WalletView />} />
              <Route path="profile" element={<ProfileView />} />
              <Route path="signin" element={<SigninView />} />
              <Route path="signout" element={<SignoutView />} />
              <Route path="signup" element={<SignupView />} />
              <Route path="create-password" element={<CreatePasswordView />} />
              <Route path="forgot-password" element={<ForgotPasswordView />} />
              <Route path="register-phone" element={<RegisterPhoneView />} />
              <Route path="verification" element={<VerificationView />} />
              <Route path="*" element={<HomeView />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}

initRecaptcha().then(() => { initFacebookSdk().then(startApp); });



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
